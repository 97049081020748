.info {
  background-color: #7F8694;
  padding: 60px 0;
}

.info__container {
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 0 70px;
}

.info__title {
  position: relative;
  padding-left: 95px;
  font-family: 'roboto_slabregular', serif;
  font-size: 30px;
  color: #444955;
}

.info__title:before {
  content: '';
  position: absolute;
  left: -1px;
  top: 23px;
  width: 65px;
  height: 5px;
  border-radius: 2.5px;
  background-color: #B36455;
}

.info__text {
  color: #FFFFFF;
  font-family: 'open_sansregular', serif;
  font-size: 19px;
  line-height: 26px;
}

#topSlide {
  background-image: url('../../assets/img/main-3.jpg');
  background-size: cover;
  background-position: left top;
}

@media (min-width: 768px) and (max-width : 950px) {
  #topSlide {
    background-position: -200px top;
  }
}