.toolsPage {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  width: 100%;
}

.toolsPage__mainTitle {
  font-family: 'roboto_slabregular', Arial, serif;
  font-size: 25px;
  font-weight: 400;
  color: #000000;
  margin: 0 0 40px 0;
}

.toolsPage__addTitle {
  margin-top: 70px;
  margin-bottom: 47px;
}

.toolsPage__topInfo {
  background-color: rgb(243,245,248);
  border-radius: 4px;
  height: 350px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
}

.topInfo__left {
  width: 685px;
  padding: 35px 10px 0 54px;
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

.topInfo__text {
  font-family: 'open_sanslight', Arial, serif;
  font-size: 19px;
  line-height: 26px;
  color: #000000;
  margin: 0;
  padding-right: 20px;
}

.topInfo__downloadButton {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 216px;
  height: 49px;
  background-color: #FFFFFF;
  padding-left: 25px;
  font-family: 'open_sanslight', Arial, serif;
  font-size: 16px;
  color: #000000;
  border: none;
  border-radius: 6px;
  -webkit-box-shadow: 0 1px 8px 0 rgba(184, 184, 184, 0.67);
  box-shadow: 0 1px 8px 0 rgba(184, 184, 184, 0.67);
  cursor: pointer;
  margin-top: 45px;
  text-decoration: none;
  display: none;
}

.topInfo__downloadButton:hover {
  color: #965d4f;
}

.topInfo__downloadButton:after {
  content: '';
  background-image: url('../../../assets/img/download_icon.png');
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  width: 70px;
  height: 49px;
  border-radius: 6px;
  background-color: rgb(243,245,248);
  right: 0;
}

.topInfo__right {
  width: 100%;
  background-color: rgb(127,134,148);
}

.toolsPage__tools {
  width: 100%;
  padding-bottom: 3px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.tools__collapse {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.collapse__header {
  font-family: 'roboto_slabregular', Arial, serif;
  font-size: 25px;
  color: #000000;
  background-color: #f3f5f8;
  padding-left: 66px;
  line-height: 69px;
  cursor: pointer;
  margin: 0;
  text-align: left;
  -webkit-transition: 0.32s;
  -o-transition: 0.32s;
  transition: 0.32s;
  border: 1px solid rgb(169, 181, 209);
}

.is-wrapper-open > .collapse__header {
  background-color: #7F8694;
  color: #FFFFFF;
}

.tools__collapse > .collapse_body {
  height: 0;
  -webkit-transition: 0.25s;
  -o-transition: 0.25s;
  transition: 0.25s;
  overflow: hidden;
}

.is-wrapper-open > .collapse_body {
  height: 1060px;
  padding: 33px 66px 55px;
  border-bottom: 1px solid rgb(169, 181, 209);
}

.collapse_body {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  padding: 0 67px;
  border-left: 1px solid rgb(169, 181, 209);
  border-right: 1px solid rgb(169, 181, 209);
}


.collapse__title {
  font-family: 'open_sansregular', Arial, serif;
  font-size: 19px;
  color: #000000;
  margin: 0 0 30px 0;
}

.collapse__img {
  height: 407px;
  width: 650px;
  margin: auto;
}

.collapse__text {
  font-family: 'open_sanslight', Arial, serif;
  font-size: 19px;
  color: #000000;
  line-height: 26px;
  margin: 0;
}

.collapse__info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-top: 69px;
}

.collapse__descriptions {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 485px;
  padding: 35px 37px 48px 37px;
  border: 1px solid rgb(216, 218, 223);
  border-radius: 10px;

}

.collapse__desTitle {
  font-family: 'open_sansregular', Arial, serif;
  font-size: 19px;
  color: #000000;
  line-height: 26px;
  margin: 0 0 30px 0;
  text-align: center;
}

.collapse__textMark {
  font-family: 'open_sanslight', Arial, serif;
  font-size: 19px;
  color: #000000;
  margin: 0;
  text-align: center;
  min-width: 409px;
  position: relative;
}

.collapse__textMark:before {
  content: '';
  position: absolute;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  left: -10px;
  top: 5px;
  width: 15px;
  height: 9px;
  border: 1px solid rgb(202, 178, 171);
  background-color: rgb(148, 100, 86);
}

@media (min-width: 320px) and (max-width : 600px) {
  //.collapse__title {
  //  display: none;
  //}

  .collapse__img {
    margin-top: 5px;
    margin-bottom: 5px;
  }
}

@media (min-width: 1001px) and (max-width : 1139px) {
  .products {
    background-position: right 1100px;
    background-size: 125px auto;
  }

  .products__topText {
    margin-bottom: 50px;
  }

  .products__menu {
    -ms-flex-pack: distribute;
    justify-content: space-around;
    margin-bottom: 50px;
  }

  .products__card {
    width: 230px;
    height: 425px;
  }

  .cards__image {
    margin: 30px 0 25px 0;
  }

  .toolsPage__topInfo {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  .topInfo__left {
    width: 55%;
    padding: 35px 10px 0 44px;
    -ms-flex-negative: 0;
    flex-shrink: 0;
  }

  .topInfo__right {
    width: 42%;
    padding: 35px 0 0 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
  }

  .topInfo__slider {
    width: 100%;
    height: 100%;
  }

  .prodSlider {
    height: 100%;
  }

  .prodSlider__elem {
    padding-top: 0;
    height: 315px;
  }

  .toolsPage__addTitle {
    margin-top: 50px;
    margin-bottom: 37px;
  }

  .is-wrapper-open > .collapse__header {
    padding-left: 25px;
  }

  .is-wrapper-open > .collapse_body {
    height: 1060px;
    padding: 25px;
  }

  .collapse__descriptions {
    width: 48%;
  }

  .tools {
    margin-bottom: 5px;
  }
}

@media (min-width: 851px) and (max-width : 1000px) {
  .products__card {
    width: 200px;
    height: 365px;
  }

  .products__menu {
    margin-bottom: 40px;
  }

  .cards__image {
    margin: 20px 0 15px 0;
  }

  .cards__title {
    font-size: 23px;
  }

  .cards__link {
    bottom: 20px;
  }

  .toolsPage__topInfo {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  .topInfo__left {
    width: 55%;
    padding: 20px 10px 0 20px;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    position: relative;
  }

  .topInfo__right {
    width: 42%;
    padding: 35px 0 0 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
  }

  .topInfo__downloadButton {
    position: absolute;
    left: 20px;
    bottom: 20px;
  }

  .topInfo__slider {
    width: 100%;
    height: 100%;
  }

  .prodSlider {
    height: 100%;
  }

  .prodSlider__elem {
    padding-top: 0;
    height: 315px;
  }

  .toolsPage__addTitle {
    margin-top: 40px;
    margin-bottom: 27px;
    padding-left: 20px;
  }

  .collapse__info {
    margin-top: 20px;
  }

  .is-wrapper-open > .collapse__header {
    padding-left: 20px;
    line-height: 62px;
  }

  .collapse__header {
    padding-left: 20px;
    line-height: 62px;
  }

  .is-wrapper-open > .collapse_body {
    height: 1060px;
    padding: 20px;
  }

  .collapse__textMark {
    font-size: 17px;
    min-width: 250px;
    position: relative;
    text-align: left;
  }

  .collapse__desTitle {
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
  }

  .collapse__textMark:before {
    left: -25px;
    top: 6px;
  }

  .collapse__descriptions {
    width: 48%;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    padding-bottom: 30px;
    padding-top: 25px;
  }
}

@media (min-width: 769px) and (max-width : 850px) {
  .products__topText {
    margin-bottom: 35px;
  }

  .products__card {
    width: 160px;
    height: 315px;
  }

  .products__addTitle {
    font-size: 26px;
  }

  .products__addText {
    font-size: 17px;
  }

  .toolsPage__topInfo {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  .cards__image {
    margin: 10px 0 5px 0;
  }

  .cards__image > img {
    height: 120px;
  }

  .cards__title {
    margin-top: 10px;
    font-size: 20px;
    padding: 0 10px;
  }

  .cards__link {
    bottom: 15px;
    width: 100px;
    border-radius: 5px;
    line-height: 37px;
  }

  .products__menu {
    margin-bottom: 20px;
  }

  .topInfo__left {
    width: 55%;
    padding: 15px 10px 0 15px;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    position: relative;
  }

  .topInfo__text {
    font-size: 18px;
    line-height: 25px;
  }

  .topInfo__right {
    width: 42%;
    padding: 35px 0 0 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
  }

  .topInfo__slider {
    width: 100%;
    height: 100%;
  }

  .prodSlider {
    height: 100%;
  }

  .prodSlider__elem {
    padding-top: 0;
    height: 315px;
  }

  .topInfo__downloadButton {
    position: absolute;
    left: 15px;
    bottom: 15px;
  }

  .toolsPage__mainTitle {
    margin-top: 10px;
    margin-bottom: 30px;
    font-size: 26px;
  }

  .toolsPage__addTitle {
    font-size: 26px;
    margin-top: 24px;
    margin-bottom: 16px;
  }

  .toolsPage__addTitle {
    margin-top: 40px;
    margin-bottom: 27px;
    padding-left: 15px;
  }

  .collapse__info {
    margin-top: 20px;
  }

  .is-wrapper-open > .collapse__header {
    padding-left: 15px;
    line-height: 62px;
  }

  .collapse__header {
    padding-left: 15px;
    line-height: 62px;
  }

  .is-wrapper-open > .collapse_body {
    height: 1060px;
    padding: 15px;
  }

  .collapse__textMark {
    font-size: 17px;
    min-width: 250px;
    position: relative;
    text-align: left;
  }

  .collapse__desTitle {
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
  }

  .collapse__textMark:before {
    left: -25px;
    top: 6px;
  }

  .collapse__descriptions {
    width: 48%;
    //margin: 0 10px;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    padding-bottom: 30px;
    padding-top: 25px;
  }
}

@media (min-width: 481px) and (max-width : 768px) {
  .products__topTitle {
    font-size: 26px;
    margin: 23px 0 28px 0;
  }

  .products {
    background-image: none;
  }

  .products__topText {
    margin-bottom: 25px;
    font-size: 18px;
    line-height: 25px;
  }

  .products__card {
    width: 105px;
    height: 215px;
  }

  .products__addTitle {
    font-size: 26px;
  }

  .products__addText {
    font-size: 17px;
  }

  .toolsPage__topInfo {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  .cards__image {
    margin: 10px 0 5px 0;
  }

  .cards__image > img {
    height: 80px;
  }

  .cards__title {
    margin-top: 6px;
    font-size: 13px;
    padding: 0 4px;
  }

  .cards__link {
    bottom: 10px;
    width: 80px;
    border-radius: 5px;
    font-size: 12px;
    line-height: 30px;
  }

  .products__menu {
    margin-bottom: 20px;
  }

  .topInfo {
    height: auto;
  }

  .topInfo__left {
    width: 100%;
    padding: 12px 10px 20px 12px;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    position: relative;
  }

  .topInfo__right {
    display: none;
  }

  .topInfo__text {
    font-size: 18px;
    line-height: 25px;
  }

  .topInfo__slider {
    width: 100%;
    height: 100%;
  }

  .prodSlider {
    height: 100%;
  }

  .prodSlider__elem {
    padding-top: 0;
    height: 315px;
  }

  .topInfo__downloadButton {
    position: absolute;
    left: 12px;
    bottom: 12px;
  }

  .toolsPage__mainTitle {
    margin-top: 7px;
    margin-bottom: 22px;
    font-size: 23px;
  }

  .toolsPage__addTitle {
    font-size: 26px;
    margin-top: 24px;
    margin-bottom: 16px;
  }

  .toolsPage__addTitle {
    margin-top: 30px;
    margin-bottom: 17px;
    padding-left: 12px;
  }

  .collapse__info {
    margin-top: 20px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .is-wrapper-open > .collapse__header {
    padding-left: 12px;
    line-height: 62px;
  }

  .collapse__img {
    width: 100%;
    height: auto;
  }

  .collapse__body > img {
    width: 100%;
    height: auto;
  }


  .collapse__header {
    padding-left: 12px;
    line-height: 62px;
  }

  .is-wrapper-open > .collapse_body {
    min-height: 1060px;
    max-height: 2000px;
    height: auto;
    padding: 12px;
  }

  .collapse__textMark {
    font-size: 17px;
    min-width: 250px;
    position: relative;
    text-align: left;
  }

  .collapse__desTitle {
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
  }

  .collapse__textMark:before {
    left: -25px;
    top: 6px;
  }

  .collapse__descriptions {
    width: 100%;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    padding-bottom: 30px;
    margin: 5px 0;
    padding-top: 18px;
  }
}

@media (min-width: 320px) and (max-width : 480px) {
  .products {
    background-image: none;
  }

  .products__topTitle {
    font-size: 26px;
    margin: 23px 0 28px 0;
  }

  .products__topText {
    margin-bottom: 25px;
    font-size: 18px;
    line-height: 25px;
  }

  .products__card {
    width: 100%;
    height: 120px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .products__addTitle {
    font-size: 26px;
  }

  .products__addText {
    font-size: 17px;
  }

  .toolsPage__topInfo {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  .cards__image {
    margin: 10px 0 5px 10px;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
  }

  .cards__image > img {
    width: 85px;
    height: auto;
  }

  .products__link {
    margin: 5px 0;
  }

  .cards__title {
    margin-top: 20px;
    margin-left: 20px;
    text-align: left;
    font-size: 16px;
    padding: 0 4px;
  }

  .cards__link {
    bottom: 15px;
    right: 15px;
    width: 80px;
    border-radius: 5px;
    font-size: 12px;
    line-height: 30px;
  }

  .products__menu {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    margin-bottom: 40px;
  }

  .topInfo {
    height: auto;
  }

  .topInfo__left {
    width: 100%;
    padding: 12px 10px 80px 12px;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    position: relative;
  }

  .topInfo__right {
    display: none;
  }

  .topInfo__text {
    font-size: 18px;
    line-height: 25px;
  }

  .topInfo__slider {
    width: 100%;
    height: 100%;
  }

  .prodSlider {
    height: 100%;
  }

  .prodSlider__elem {
    padding-top: 0;
    height: 315px;
  }

  .topInfo__downloadButton {
    position: absolute;
    left: 12px;
    bottom: 12px;
  }

  .toolsPage__mainTitle {
    margin-top: 7px;
    margin-bottom: 22px;
    font-size: 23px;
  }

  .toolsPage__addTitle {
    font-size: 26px;
    margin-top: 24px;
    margin-bottom: 16px;
  }

  .toolsPage__addTitle {
    margin-top: 30px;
    margin-bottom: 17px;
    padding-left: 12px;
  }

  .collapse__info {
    margin-top: 20px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .is-wrapper-open > .collapse__header {
    padding-left: 12px;
    line-height: 62px;
  }

  .collapse__img {
    width: 100%;
    height: auto;
  }

  .collapse__body > img {
    width: 100%;
    height: auto;
  }


  .collapse__header {
    padding-left: 12px;
    line-height: 62px;
  }

  .is-wrapper-open > .collapse_body {
    min-height: 650px;
    max-height: 2000px;
    height: auto;
    padding: 12px;
  }

  .collapse__textMark {
    font-size: 17px;
    min-width: 250px;
    position: relative;
    text-align: left;
  }

  .collapse__desTitle {
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
  }

  .collapse__textMark:before {
    left: -25px;
    top: 6px;
  }

  .collapse__descriptions {
    width: 100%;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    padding-bottom: 30px;
    margin: 5px 0;
    padding-top: 18px;
  }
}