.strategy {
  width: 1005px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 20px 70px;
}

.reverse {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
}

.strategies {
  background-color: white;
  padding-bottom: 0;
  max-width: 1920px;
  background-image: url('../../assets/img/main_circle_right.png');
  background-repeat: no-repeat;
  background-position: right 380px;
  background-size: 120px;
}

.strategy__info {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  padding-left: 100px;
}

.strategy__title {
  font-family: 'roboto_slabregular', serif;
  font-size: 30px;
  color: #000000;
  position: relative;
}

.strategy__title:before {
  content: '';
  position: absolute;
  left: -95px;
  top: 16px;
  width: 65px;
  height: 5px;
  border-radius: 2.5px;
  background-color: #B36455;
}

.strategy__text {
  font-family: 'open_sansregular', serif;
  font-size: 19px;
  line-height: 26px;
  color: #000000;
}

.strategyTitle {
  font-family: 'montserratsemibold', serif;
  font-size: 30px;
  line-height: 55px;
  color: #a46455;
  margin: 5px 0 10px 0;
}

.strategy__image {
  height: 100%;
}

.strategy__image > img {
  height: 100%;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

.strategyTitle {
  font-family: 'montserratsemibold', serif;
  font-size: 30px;
  line-height: 55px;
  color: #a46455;
  margin: 5px 0 10px 0;
}

.strategy__image {
  height: 100%;
}

.strategy__image > img {
  height: 100%;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

@media (min-width: 768px) and (max-width : 5000px) {
  .strategyTitle {
    margin: 15px 0;
  }

  .strategy {
    width: 85vw;
    padding: 0 15px;
    height: 24vh;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
  }

  .strategy__info {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-pack: distribute;
    justify-content: space-around;
    text-align: left;
    padding-left: 15px;
  }

  .strategy__title, .strategy__text {
    margin: 0;
  }

  .strategy__image {

  }

  .strategy__title {
    padding-left: 80px;
  }

  .strategy__title:before {
    left: 0;
    top: 23px;
  }

  .strategyTitle {
    font-size: 25px;
    line-height: 40px;
  }
}