.topInfo__slider {
  width: 455px;
  height: 350px;
}

.prodSlider {
  width: 100%;
  height: 350px;
}

.prodSlider__elem {
  text-align: center;
  width: 100%;
  height: 350px;
  background-color: rgb(127,134,148);
  padding-top: 43px;
}

.prodSlider__elem > img {
  margin: auto;
}


.prodSlider li.slick-active {
  border-radius: 15px;
  width: 13px;
  height: 9px;
}

.prodSlider .ant-carousel .slick-dots li {
  width: 13px;
  height: 9px;
  margin-inline: 6px;
}

.prodSlider .ant-carousel .slick-dots li.slick-active {
  width: 13px;
}

.prodSlider .ant-carousel .slick-dots li.slick-active button {
  background-color: #946456;
}

.prodSlider .ant-carousel .slick-dots li button {
  height: 9px;
  background: white;
  opacity: 1;
}

.prodSlider .ant-carousel .slick-dots li button:hover {
  background-color: #965d4f;
}