.contacts {
  background-image: url('../../assets/img/circle_left_contacts.png'),
  url('../../assets/img/circle_right_contacts.png');
  background-repeat: no-repeat, no-repeat;
  background-position: left 100px, right 320px;
}

.location {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  width: 100%;
  justify-content: flex-start;
  margin-top: 35px;
  margin-bottom: 60px;
}

.preload-container {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 695px;
  height: 427px;
  background-color: whitesmoke;
}

.yandex__map {
  width: 695px;
  height: 427px;
  -webkit-box-shadow: 0 0 21px 0 rgba(135, 138, 144, 0.64);
  box-shadow: 0 0 21px 0 rgba(135, 138, 144, 0.64);
}

.location__info {
  padding-left: 85px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.location__title {
  font-family: 'montserratsemibold', serif;
  font-size: 30px;
  color: #000000;
}

.location__text {
  font-family: 'open_sanslight', serif;
  font-size: 19px;
  line-height: 26px;
  color: #000000;
  margin: 25px 0;
  text-decoration: none;
  padding-left: 50px;
  position: relative;
}

.contacts__title {
  font-family: 'roboto_slabregular', serif;
  font-size: 30px;
  color: #000000;
  margin-top: 5px;
}

.address:before, .con_phone:before, .con_mail:before {
  position: absolute;
  content: '';
  left: 3px;
}

.address:before {
  width: 23px;
  height: 32px;
  left: 7px;
  top: 4px;
  background-image: url('../../assets/img/geolocation.png');
}

.con_phone:before {
  width: 33px;
  height: 26px;
  background-image: url('../../assets/img/phone.png');
}

.contacts__text {
  font-family: 'open_sanslight', serif;
  font-size: 19px;
  line-height: 26px;
  color: #000000;
}

.con_mail:before {
  width: 30px;
  height: 23px;
  background-image: url('../../assets/img/mail.png');
}

.con_phone:hover {
  color: #a46455;
}

.con_mail:hover {
  color: #a46455;
}

.contacts__imgs {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-between;
}

.contacts__img_photo {
  width: 48%;
}


@media (min-width: 320px) and (max-width : 1080px) {
  .location__info {
    padding-left: 0;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .location__text {
    margin-top: 14px;
    margin-bottom: 14px;
  }

  .preload-container, .yandex__map {
    width: 100%;
    margin-top: 30px;
    height: 35vh;
  }

  .yandex__map {
    margin-top: 0
  }

  .location {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 100%;
  }

  .con_phone {
    -webkit-tap-highlight-color: transparent;
  }

  .con_mail {
    -webkit-tap-highlight-color: transparent;
  }
}


@media (min-width: 480px) and (max-width : 1080px) {
  .location__title {
    margin: 25px 0 12px 0;
  }

  .contacts {
    background-position: left 100px, right 400px;
    background-size: 0 0, 140px auto;
  }
}

@media (min-width: 320px) and (max-width : 768px) {
  .logo__adaptive {
    display: block;
    position: absolute;
    left: 20px;
    top: 20px;
    z-index: 100;
  }
}

@media only screen and (min-width : 320px) and (max-width : 480px) {
  .location {
    margin-bottom: 35px;
  }

  .location__text {
    margin-top: 12px;
    margin-bottom: 12px;
    font-size: 17px;
  }

  .contacts__text {
    font-size: 17px;
    line-height: 25px;
  }

  .contacts {
    margin-bottom: 10px;
    background-position: left 100px, right 520px;
    background-size: 0 0, 100px auto;
  }

}


@media only screen and (min-width : 461px) and (max-width : 767px) {
  .partners__container {
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    padding: 0 15px;
  }

  .yandex__map {
    width: calc(100vw - 30px);
    height: 35vh;
  }

  .contacts__img_photo {
    width: 100%;
    margin-bottom: 10px;
  }
}

@media only screen and (min-width : 320px) and (max-width : 460px) {
  .container {
    padding: 0 10px;
  }

  .partners__container {
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    padding: 0 10px;
  }

  .partners_title {
    margin-top: 36px;
  }

  .yandex__map {
    width: calc(100vw - 16px);
    height: 35vh;
  }

  .contacts__img_photo {
    width: 100%;
    margin-bottom: 8px;
  }
}