%v-flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  margin: auto;
  width: 100%;
  max-width: 1170px;
  padding: 0 15px;
  height: 100%;
}

.network-e {
  position: absolute;
  z-index: 1000;
  width: 600px;
  left: calc(50% - 300px);
}

.main {
  @extend %v-flex;
  min-height: 100vh;
  background-color: #FFFFFF;
}

::-webkit-scrollbar {
  width: 0;
}

main {
  margin-top: 90px;
  width: 100%;
  margin-bottom: auto;
}

$mainColor: #FFFFFF;


@media (min-height: 1600px) {
  body {
    background-color: $mainColor;
  }

  .main {
    position: relative;
    top: calc(50vh - 770px);
    padding: 45px;
    min-height: 1540px;
    background-color: $mainColor;
    border: 3px solid #a46455;
    margin: auto;
  }

  header {
    background-color: $mainColor;
    position: relative;
  }

  footer {
    background-color: $mainColor;
    margin: 0;
  }

  .about__container {
    background-color: $mainColor;
  }

  .partners__container {
    background-color: $mainColor;
  }
}

