$main-background: #F2F6FA;

.all-border {
  border: 1px solid black;
}

html {
  height: 100%;
  scroll-behavior: smooth;
}


* {
  outline-color: #808592;
  outline-width: 15px;
}


body {
  font-family: 'open_sansregular', sans-serif;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  position: relative;
  overflow-y: auto;
}


body.modal-open {
  overflow: hidden;
  position: fixed;
}

* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

* {
  scrollbar-width: thin;
  scrollbar-color: 965d4f rgba(0,0,0,0);
}

*::-webkit-scrollbar {
  height: 8px;
  width: 8px;
  position: absolute;
  left: 0;
}

*::-webkit-scrollbar-track {
  background: #FFFFFF;
}
*::-webkit-scrollbar-thumb {
  background-color: #965d4f;
  border-radius: 1px;
  //border: 0px solid orange;
}