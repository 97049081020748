#services {
  background-color: #FAF7F9;
  background-image: url('../../assets/img/main_circle_right.png');
  background-repeat: no-repeat;
  background-position: right 350px;
  background-size: 140px;
  max-width: 4920px;
}

#services2 {
  background-color: #FAF7F9;
  background-image: url('../../assets/img/main_circle_right.png');
  background-repeat: no-repeat;
  background-position: right 450px;
  background-size: 150px;
  max-width: 4920px;
}

.services {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding-bottom: 87px;
  width: 100%;
}

.servicesTitle {
  font-family: 'roboto_slabregular', serif;
  font-size: 30px;
  color: #000000;
  width: 100%;
  margin-top: 5px;
  margin-bottom: 10px;
}

.service {
  width: 540px;
  height: 560px;
  background-color: #FFFFFF;
  border-radius: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: 180px;
  z-index: 5;
}

.wrap__service {
  position: relative;
}

.wrap__service:hover > .service {
  -webkit-box-shadow: 0 0 21px 0 rgba(135, 138, 144, 0.34);
  box-shadow: 0 0 21px 0 rgba(135, 138, 144, 0.34);
  z-index: 5;
}

.add__shadow {
  width: 308px;
  min-height: 308px;
  border-radius: 50%;
  position: absolute;
  z-index: 4;
  left: 116px;
  top: 49px;
}


.wrap__service:hover > .add__shadow {
  -webkit-box-shadow: 0 0 21px 2px rgba(135, 138, 144, 0.34);
  box-shadow: 0 0 21px 2px rgba(135, 138, 144, 0.34);
}

.service__image {
  width: 308px;
  min-height: 308px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 50%;
  background-color: rgb(241, 239, 241);
  -webkit-box-shadow: 0.487px -3.97px 18px 0px rgba(124, 124, 124, 0.14);
  box-shadow: 0.487px -3.97px 18px 0px rgba(124, 124, 124, 0.14);
  overflow: hidden;
  position: relative;
  top: -132px;
  z-index: 3;
}

.service__image > img {
  width: auto;
  height: 200px;
  border-radius: 0 0 30px 30px;
  border-radius: 45%;
}


.service__info {
  position: relative;
  top: -127px;
  padding: 0 70px;
}

.service__title {
  text-align: center;
  font-family: 'roboto_slabregular', serif;
  font-size: 25px;
  color: #000000;
}

.service__text {
  font-family: 'open_sanslight', serif;
  font-size: 21px;
  line-height: 26px;
  font-weight: 500;
  color: #000000;
}

.service:hover .service__link {
  background-color: #885D51;
}

.service__link {
  text-decoration: none;
  text-align: center;
  width: 160px;
  line-height: 54px;
  background-color: #946456;
  color: white;
  border-radius: 25px;
  position: absolute;
  bottom: 42px;
}

.service__link:hover {
  background-color: #885D51;
  color: #FFFFFF;
}

.service__link:active {
  background-color: #80584C;
  color: #FFFFFF;
}


.services {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding-bottom: 87px;

}

@media (min-width: 1050px) and (max-width : 3000px) {
  .services {
    padding-bottom: 0;
    width: 100%;
    -ms-flex-pack: distribute;
    justify-content: space-around;
  }

  .service {
    margin-top: 150px;
    height: calc(100% - 150px);
    width: 100%;
  }

  .wrap__service {
    height: calc(95vh - 200px);
    width: 44%;
  }

  .service__image {
    width: 300px;
    min-height: 300px;
  }

  .add__shadow {
    width: 300px;
    min-height: 300px;
    left: calc(50% - 150px);
    top: 25px
  }

  .service__info {
    padding: 0 30px;
  }

  @media (min-height: 700px) and (max-height: 850px) {
    .wrap__service {
      height: calc(95vh - 180px);
      width: 470px;
    }

    .service {
      margin-top: 110px;
      height: 390px;
      width: 100%;
    }

    .service__image {
      width: 180px;
      min-height: 180px;
      top: -90px;
      margin-bottom: 50px;
    }

    .service__image > img {
      height: 150px;
      width: auto;
    }

    .add__shadow {
      width: 150px;
      min-height: 150px;
      left: calc(50% - 75px);
      top: 15px
    }

    .service__link {
      bottom: 20px;
    }
  }

  @media (min-height: 600px) and (max-height: 699px) {
    .wrap__service {
      height: 420px;
      width: 450px;
    }

    .service {
      margin-top: 100px;
      height: 320px;
      width: 100%;
    }

    .service__image {
      width: 150px;
      min-height: 150px;
      top: -90px;
      margin-bottom: 30px;
    }

    .service__info {
      padding: 0 15px;
    }

    .service__image > img {
      height: 120px;
      width: auto;
    }

    .service__title {
      margin: 15px 0 15px 0;
    }

    .service__text {
      margin: 5px 0;
      font-size: 18px;
      line-height: 25px;
    }

    .add__shadow {
      width: 150px;
      min-height: 150px;
      left: calc(50% - 75px);
      top: 15px
    }

    .service__link {
      bottom: 10px;
    }
  }
}


@media (min-width: 768px) and (max-width : 1049px) {
  .nav {
    width: 20px;
  }

  .services {
    -ms-flex-pack: distribute;
    justify-content: space-around;
    height: calc(100vh - 170px);
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 0;
  }

  .wrap__service {
    height: 420px;
    width: 44%;
    margin-top: 20px;
  }

  .service {
    margin-top: auto;
    height: 100%;
    width: 100%;
  }

  .service__image {
    width: 150px;
    min-height: 150px;
    top: -60px;
    margin-bottom: 30px;
  }

  .service__info {
    padding: 0 15px;
    top: -100px;
  }

  .service__image > img {
    height: 130px;
    width: auto;
    border-radius: 45%;
  }

  .service__title {
    font-size: 24px;
    margin: 15px 0 15px 0;
    line-height: 30px;
  }

  .service__text {
    margin: 5px 0;
    font-size: 16px;
    line-height: 23px;
  }

  .add__shadow {
    width: 150px;
    min-height: 150px;
    left: calc(50% - 75px);
    top: 15px
  }

  .service__link {
    bottom: 10px;
  }

  @media (min-height: 650px) and (max-height: 745px) {
    .service__image {
      width: 150px;
      min-height: 150px;
      top: -10px;
      margin-bottom: 30px;
    }

    .service__info {
      padding: 0 15px;
      top: -40px;
    }

    .service__link {
      bottom: 10px;
      line-height: 45px;
      width: 140px;
    }
  }

  @media (min-height: 550px) and (max-height: 649px) {
    .wrap__service {
      height: 350px;
      margin-top: 10px;
      width: 40%;
    }

    .service__image {
      display: none;
    }

    .service__info {
      padding: 0 15px;
      top: 10px;
    }

    .service__link {
      bottom: 10px;
      line-height: 45px;
      width: 140px;
    }
  }
}







