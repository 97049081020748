@font-face {
    font-family: 'montserratextrabold';
    src: url('../fonts/Montserrat-ExtraBold.woff2') format('woff2'),
    url('../fonts/Montserrat-ExtraBold.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'montserratsemibold';
    src: url('../fonts/Montserrat-SemiBold.woff2') format('woff2'),
    url('../fonts/Montserrat-SemiBold.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'montserratregular';
    src: url('../fonts/Montserrat-Regular.woff2') format('woff2'),
    url('../fonts/Montserrat-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'open_sanslight';
    src: url('../fonts/OpenSans-Light.woff2') format('woff2'),
    url('../fonts/OpenSans-Light.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'open_sansregular';
    src: url('../fonts/OpenSans-Regular.woff2') format('woff2'),
    url('../fonts/OpenSans-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'roboto_slabregular';
    src: url('../fonts/RobotoSlab-Regular.woff2') format('woff2'),
    url('../fonts/RobotoSlab-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}