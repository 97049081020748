.nav {
  position: fixed;
  right: 0;
  bottom: 100px;
  left: 14px;
  background-color: rgba(10, 10, 10, 0);
  max-width: 300px;
  border-radius: 5px;
  z-index: 100;
}

.nav-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  padding: 5px;
  margin: 0;
  list-style: none;
}

.nav-list__item-link {
  display: block;
  padding: 7px 15px 7px 15px;
  color: #000;
  text-decoration: none;
  -webkit-transition: 0.2s;
  -o-transition: 0.2s;
  transition: 0.2s;
  -ms-flex-negative: 1;
  flex-shrink: 1;
  font-family: 'montserratregular', serif;
  font-size: 18px;
  line-height: 24px;
  position: relative;
}

@media (min-width: 767px) and (max-width : 1400px) {
  .nav-list__item-link {
    padding: 3px 10px 5px 12px;
  }

  .nav {
    bottom: 110px;
  }
}

.nav-list__item-link:before {
  content: '';
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: black;
  position: absolute;
  left: -10px;
  top: 12px;
  -webkit-transition: 0.15s;
  -o-transition: 0.15s;
  transition: 0.15s;
}


.nav-list__item-link:hover {
  color: #946456;
}

.nav-list__item-link--active {
  color: #a46455;
}


.nav-list__item-link:hover:before {
  background-color: #946456;
  -webkit-transition: 0.15s;
  -o-transition: 0.15s;
  transition: 0.15s;
}

.nav-list__item-link--active:before {
  background-color: #a46455;
  -webkit-transition: 0.15s;
  -o-transition: 0.15s;
  transition: 0.15s;
}

@media (min-width: 320px) and (max-width: 1400px) {
  .nav-list__item-link {
    font-size: 0;
  }

  .nav-list__item-link:before {
    width: 15px;
    height: 15px;
  }

  .nav-list__item {
    margin-bottom: 2px;
  }
}