.partners {
  background-color: #FFFFFF;
  background-image: url('../../assets/img/part_circle_right.png');
  background-repeat: no-repeat;
  background-position: right 155px;

  .partners__container {
    align-items: flex-start;
  }

  .partners_title {
    font-family: 'montserratsemibold', serif;
    font-size: 30px;
    color: #a46455;
  }

  .partners_text {
    font-family: 'open_sanslight', Arial, serif;
    font-size: 19px;
    line-height: 26px;
    color: #000000;
  }
}

