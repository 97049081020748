footer {
  width: 100%;
  height: 110px;
  text-align: center;
  background-color: #FFFFFF;
  margin-top: -1px;
}

.footer_container {
  max-width: 1170px;
  height: 100%;
  padding: 0 15px;
  margin: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-family: 'open_sansregular', serif;
  font-size: 19px;
  color: #000000;
}

.elem1 {
  margin-right: 28px;
}

.elem2 {
  margin-right: 58px;
}

.footer__right {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.footer__link {
  margin-right: 0;
  text-decoration: none;
  color: #DA242F;
  border-bottom: 2px solid #DA242F;
}

.footer__link:hover {
  color: #a46455;
  border-bottom: 2px solid #a46455;
}

.footer__tube {
  width: 49px;
  height: 37px;
}

.footer__tube > img {
  width: 49px;
  height: 37px;
}


@media only screen and (min-width : 320px) and (max-width : 767px) {
  footer {
    margin-top: 5px;
  }

  .footer__right {
    position: relative;
    width: 70px;
    height: 100%;
  }

  .footer__link {
    display: none;
  }
}

@media only screen and (min-width : 561px) and (max-width : 767px) {
  .footer__left {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-item-align: stretch;
    -ms-grid-row-align: stretch;
    align-self: stretch;
  }

  .footer__left > span {
    margin: 5px 15px 5px 0;
  }

  .footer__right {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 70px;
    height: 110px;
  }

  .footer_container {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 0 15px;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
}

@media only screen and (min-width : 320px) and (max-width : 460px) {
  .footer_container {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 0 10px;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
}

@media only screen and (min-width : 320px) and (max-width : 560px) {
  .footer__left {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -ms-flex-item-align: stretch;
    -ms-grid-row-align: stretch;
    align-self: stretch;
  }

  .footer__left > span {
    margin: 5px 0;
  }
}