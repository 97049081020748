.products {
  background-image: url('../../assets/img/prods_circle_right.png');
  background-repeat: no-repeat;
  background-position: right 1200px;
}

.products__topTitle {
  font-family: 'montserratsemibold', serif;
  font-size: 30px;
  color: #a46455;
  height: 30px;
  margin: 35px 0 42px 0;
}

.products__topText {
  font-family: 'open_sanslight', Arial, serif;
  font-size: 19px;
  color: #000000;
  line-height: 26px;
  text-align: center;
  margin: 0 0 100px 0;
}

.products__menu {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 80px;
}

.products__addInfo {
  width: 100%;
  text-align: left;
}

.products__addTitle {
  font-family: 'montserratsemibold', serif;
  font-size: 30px;
  color: #a46455;
  height: 30px;
  margin: 0;
}

.products__addText {
  font-family: 'open_sanslight', Arial, serif;
  font-size: 19px;
  color: #000000;
  line-height: 26px;
  text-align: left;
}


