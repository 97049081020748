header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: fixed;
  top: 0;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.955);
  z-index: 100;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.header--normal {
  height: 90px;
}

.header--less {
  height: 65px;
}

.header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  margin: 0;
  max-width: 1170px;
  padding: 0 15px;
  height: 100%;
}

.header__logo {
  margin-right: 135px;
}

.header__logo > img {
  height: 60px;
}

.logo__adaptive {
  display: none;
}

.header_menu {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: flex-end;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  //justify-content: space-between;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}

.header__topMenuLink, .header__topMenuLink--active {
  font-size: 25px;
  font-family: 'open_sansregular', serif;
  line-height: 36px;
  color: #000000;
  text-decoration: none;
  -webkit-transition: 0.25s;
  -o-transition: 0.25s;
  transition: 0.25s;
  border-bottom: 1px solid rgba(0, 0, 0, 0);
  margin-left: 55px;
  margin-right: 5px;
}

.header__topMenuLink:hover {
  color: #a46455;
  border-bottom: 1px solid #a46455;
}

.header__topMenuLink--active {
  color: #a46455;
  border-bottom: 1px solid #a46455;
}

.header__phone {
  padding-top: 2px;
  color: #a46455;
  -webkit-transition: 0.1s;
  -o-transition: 0.1s;
  transition: 0.1s;
  text-decoration: none;
  font-size: 19px;
  font-family: 'open_sansregular', serif;
  line-height: 36px;
  margin-left: 50px;
}

.header__phone:hover {
  color: #965d4f;
  font-weight: 600;
}

@media only screen and (min-width : 768px) and (max-width : 5000px) {
  .burger {
    display: none;
  }
}

@media (min-width: 993px) and (max-width : 1170px) {
  .header {
    padding: 0 12px;
  }

  .header__logo {
    margin-left: 10px;
    margin-right: 110px;
  }

  .header__phone {
    margin-right: 15px;
  }

  .header_menu {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: distribute;
    justify-content: space-around;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
  }

  .header__topMenuLink, .header__topMenuLink--active {
    margin-left: 10px;
    margin-right: 5px;
  }
}




@media (min-width: 861px) and (max-width : 992px) {
  .header__logo {
    margin-right: 60px;
  }

  .header__phone {
    margin-left: 25px;
    font-size: 18px;
  }

  .header__topMenuLink, .header__topMenuLink--active {
    font-size: 23px;
    margin-left: 30px;
    margin-right: 5px;
  }

}




@media only screen and (min-width : 768px) and (max-width : 860px) {
  .header {
    padding: 0 10px;
  }

  .header__logo {
    margin-right: 26px;
  }

  .header__logo > img {
    width: 120px;

  }

  .header__phone {
    margin-left: 15px;
    font-size: 17px;
  }

  .header__topMenuLink, .header__topMenuLink--active {
    font-size: 21px;
    margin-left: 28px;
    margin-right: 6px;
  }
}



@media only screen and (min-width : 320px) and (max-width : 767px) {
  header {
    width: 100%;
    display: block;
    left: 0;
  }

  .header__logo {
    position: absolute;
    margin-right: 0;
    width: 154px;
    top: -15px;
  }

  .header_menu {
    margin-top: 60px;
  }

  .header__phone {
    margin-left: 15px;
    font-size: 22px;
  }

  .header__topMenuLink, .header__topMenuLink--active {
    font-size: 35px;
    margin: 20px 15px;
    padding: 20px 15px;
    -webkit-transition: 0.7s;
    -o-transition: 0.7s;
    transition: 0.7s;
    -webkit-tap-highlight-color: transparent;
  }

  .header__topMenuLink--active {
    color: #FFFFFF;
    background-color: #a46455;
    position: relative;
    border-radius: 5px 0 5px 0;
  }

  .header__topMenuLink--active:before {
    content: '';
    width: 40px;
    height: 5px;
    position: absolute;
    background-color: #a46455;
    left: -55px;
    top: 27px;
  }

  .header__topMenuLink--active:after {
    content: '';
    width: 40px;
    height: 5px;
    position: absolute;
    background-color: #a46455;
    top: 27px;
    right: -55px;
  }

  .header {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-pack: distribute;
    justify-content: space-around;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 100%;
    height: calc(100vh - 50px);
    padding: 0;
    background-color: rgba(255,255,255,0.94);
    position: absolute;
    top: 50px;
    right: 10px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }

  header {
    background-color: rgba(255,255,255,0);
  }

  .menu--hide {
    width: 0;
    overflow: hidden;
    right: 0;
    top: 0;
    height: 0;
    -webkit-transition: 0.2s;
    -o-transition: 0.2s;
    transition: 0.2s;
  }

  .menu--visible {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100vw;
    height: calc(100vh - 50px);
    right: 0;
    -webkit-transition: 0.2s;
    -o-transition: 0.2s;
    transition: 0.2s;
  }

  .header_menu {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-flex: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
  }

  .header--normal {
    height: 60px;
  }

  .burger {
    display: block;
    opacity: 1;
    width: 100%;
    //background-color: rgba(255, 255, 255, 0);
    z-index: 100;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
    height: 55px;
  }

  main {
    margin-top: 42px;
  }

  .burger__button, .burger__button--active {
    width: 40px;
    height: 30px;
    cursor: pointer;
    position: fixed;
    top: 15px;
    right: 14px;
    border: none;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    background-color: rgba(0,0,0,0);
  }

  .burger__button {
    -webkit-tap-highlight-color: transparent;
  }

  .burger__button--active {
    -webkit-tap-highlight-color: transparent;
  }

  .burger__button > span {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 40px;
    height: 5px;
    background-color: #a46455;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
  }

  .burger__button:before {
    content: '';
    position: absolute;
    top: 12px;
    left: 0;
    width: 40px;
    height: 5px;
    background-color: #a46455;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
  }

  .burger__button:after {
    content: '';
    position: absolute;
    top: 24px;
    left: 0;
    width: 40px;
    height: 5px;
    background-color: #a46455;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
  }

  .burger__button--active:before {
    content: '';
    position: absolute;
    top: 16px;
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
    left: 0;
    width: 40px;
    height: 5px;
    background-color: #965d4f;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
  }

  .burger__button--active:after {
    content: '';
    position: absolute;
    top: 16px;
    left: 0;
    width: 40px;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    height: 5px;
    background-color: #965d4f;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
  }

  .network-e {
    display: none;
    width: 100vw;
    left: 0;
  }
}




@media only screen and (min-width : 461px) and (max-width : 767px) {
  .header__topMenuLink, .header__topMenuLink--active {
    font-size: 1.5rem;
    margin: 1.2rem 1.3rem;
    width: 40vw;
    text-align: center;
    padding: 10px 15px;
    -webkit-transition: 0.7s;
    -o-transition: 0.7s;
    transition: 0.7s;
  }

  .about__container {
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    padding: 0 15px;
  }
}


@media only screen and (min-width : 320px) and (max-width : 460px) {
  .header__topMenuLink, .header__topMenuLink--active {
    font-size: 1.35rem;
    margin: 1.1rem 1.1rem;
    width: 40vw;
    text-align: center;
    padding: 10px 15px;
    -webkit-transition: 0.7s;
    -o-transition: 0.7s;
    transition: 0.7s;
  }

  .about__container {
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    padding: 0 10px;
  }
}