.mobilePage {
  display: none;
}

@media (min-width: 320px) and (max-width : 767px) {
  .mobilePage {
    display: block;
  }

  .slider, .main__slider {
    display: none;
  }
}

.info__title, .info__text {
  text-align: left;
}

.slider {
  width: 100%;
  text-align: center;
  font-family: 'open_sansregular', sans-serif;
  position: relative;
}

.main__slider {
  text-align: center;
}

.scrollSlide {
  height: calc(100vh - 199px);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-bottom: 0px solid rgba(0,0,0,0);
}

.slider__img {
  width: 100%;
}


.main__slider li.slick-active {
  border-radius: 15px;
  width: 15px;
  height: 15px;
}

.main__slider .ant-carousel .slick-dots li {
  width: 15px;
  height: 15px;
  margin-inline: 5px;
}

.main__slider .ant-carousel .slick-dots li.slick-active {
  width: 15px;
}

.main__slider .ant-carousel .slick-dots li.slick-active button {
  background-color: #a46455;
}

.main__slider .ant-carousel .slick-dots li button {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  background: white;
  opacity: 1;
}

.main__slider .ant-carousel .slick-dots li button:hover {
  background-color: #965d4f;
}

.slider__prev {
  position: absolute;
  left: 100px;
  top: 100px;
}

.slider__next {
  position: absolute;
  right: 100px;
  top: 100px;
}

.slide2__container {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: calc(100vh - 200px);
}

.slide3__container {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

@media (min-width: 1050px) and (max-width : 2000px) {
  .strategyTitle {
    margin: 40px 0;
  }

  .strategy {
    padding: 40px 70px;
    height: 34vh;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    width: 1000px;
  }

  .strategy__info {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-pack: distribute;
    justify-content: space-around;
  }

  .strategy__text {
    margin: 10px 0 20px 0;
  }
}









